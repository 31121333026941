@include govuk-media-query($from: tablet) {
  .govuk-summary-list.govuk-summary-list--long-key {
    .govuk-summary-list__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .govuk-summary-list__key {
      flex-grow: 1;
      margin-bottom: 0;
    }

    .govuk-summary-list__actions {
      width: auto;
      margin-bottom: 0;
    }
  }
}
