.hmrc-notification-badge {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  line-height: 1.25;
  display: inline-block;
  min-width: 10px;
  padding: 1px 4px 0 4px;
  border-radius: 10px;
  color: govuk-colour("white");
  background: govuk-colour("red");
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

@media print {
  .hmrc-notification-badge {
    font-family: sans-serif;
  }
}

@media (min-width: 40.0625em) {
  .hmrc-notification-badge {
    font-size: 0.875rem;
    line-height: 1.42857;
  }
}

@media print {
  .hmrc-notification-badge {
    font-size: 12pt;
    line-height: 1.2;
  }
}
