@import "../../../../govuk-frontend/dist/govuk/settings/index";
@import "../../../../govuk-frontend/dist/govuk/tools/index";
@import "../../../../govuk-frontend/dist/govuk/helpers/index";
@import "../../../../govuk-frontend/dist/govuk/vendor/sass-mq";

.hmrc-add-to-a-list {
  padding-left: 0;

  @include govuk-font($size: 19);

  @include mq($from: desktop) {
    display: table;
  }
}

.hmrc-add-to-a-list--short {
  @include mq($from: desktop) {
    // to make group of q&a line up horizontally (unless there is just one group)
    width: 100%;

    // recommended for mostly short questions
    .hmrc-add-to-a-list__identifier {
      width: 30%;
    }
  }
}

.hmrc-add-to-a-list--long {
  @include mq($from: desktop) {
    // to make group of q&a line up horizontally (unless there is just one group)
    width: 100%;

    // recommended for mostly long questions
    .hmrc-add-to-a-list__identifier {
      width: 50%;
    }
  }
}

.hmrc-add-to-a-list__contents {
  display: block;
  position: relative;
  padding-bottom: govuk-spacing(1);
  border-bottom: 1px solid $govuk-border-colour;

  @include mq($from: desktop) {
    display: table-row;
    padding-bottom: 0;
    border-bottom-width: 0;
  }
}

.hmrc-add-to-a-list__identifier,
.hmrc-add-to-a-list__remove,
.hmrc-add-to-a-list__change {
  display: block;
  margin: 0;

  @include mq($from: desktop) {
    display: table-cell;
    padding: govuk-spacing(3) 0;
    border-bottom: 1px solid $govuk-border-colour;
  }
}

.hmrc-add-to-a-list__identifier {
  margin: govuk-spacing(2) govuk-spacing(9) 0 0;
  font-weight: bold;

  @include mq($from: desktop) {
    margin: 0;
  }
}

.hmrc-add-to-a-list__identifier--light {
  font-weight: normal;
}

.hmrc-add-to-a-list__remove {
  margin-top: govuk-spacing(2);
  margin-bottom: govuk-spacing(2);
  text-align: right;

  @include mq($from: desktop) {
    padding-right: 0;
  }
}

.hmrc-add-to-a-list__change {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;

  @include mq($from: desktop) {
    position: static;

    + .hmrc-add-to-a-list__remove {
      width: 6em;
    }
  }
}
