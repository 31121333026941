@import "../../../../govuk-frontend/dist/govuk/settings/index";
@import "../../../../govuk-frontend/dist/govuk/tools/index";
@import "../../../../govuk-frontend/dist/govuk/helpers/index";

.hmrc-list-with-actions {
  @include govuk-font($size: 19);

  @include govuk-media-query($from: desktop) {
    .hmrc-summary-list__key {
      width: 50%;
    }

    .hmrc-summary-list__actions {
      width: 50%;
    }

    .hmrc-list-with-actions__name {
      width: 50%;
    }
  }
}

// Kept for backward compatibility with add-to-a-list
.hmrc-list-with-actions--short {
  @include govuk-media-query($from: desktop) {
    .hmrc-summary-list__key {
      width: 30%;
    }

    .hmrc-summary-list__actions {
      width: 70%;
    }

    .hmrc-list-with-actions__name {
      width: 30%;
    }
  }
}

.hmrc-list-with-actions__item {
  display: flex;
  justify-content: space-between;
  padding: govuk-spacing(3) 0;
  border-bottom: 1px solid $govuk-border-colour;
}

.hmrc-list-with-actions__name {
  font-weight: normal;
}

.hmrc-list-with-actions__actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include govuk-media-query($from: desktop) {
    flex-direction: row;
  }
}

.hmrc-list-with-actions__action {
  margin-bottom: govuk-spacing(2);
  text-align: right;

  @include govuk-media-query($from: desktop) {
    margin-bottom: 0;
    flex-direction: row;
  }
}

.hmrc-list-with-actions__action:last-of-type {
  margin-bottom: 0;
}
