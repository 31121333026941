@import "../../../../govuk-frontend/dist/govuk/helpers/links";
@import "../../../../govuk-frontend/dist/govuk/helpers/typography";
@import "../../../../govuk-frontend/dist/govuk/utilities/clearfix";

.hmrc-user-research-banner {
  display: none;
  position: relative;
  color: govuk-colour("white");
  background-color: $govuk-brand-colour;
  box-sizing: border-box;

  @include govuk-clearfix;
  @include govuk-responsive-padding(6, "top");
  @include govuk-responsive-padding(6, "bottom");
  @include govuk-font($size: 19);

  &__container {
    position: relative;

    @include govuk-media-query($from: tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    float: left;
    margin-bottom: govuk-spacing(3);
  }

  &__title {
    @include govuk-responsive-margin(1, "bottom");

    color: govuk-colour("white");
  }

  &__link {
    @include govuk-link-common;
    @include govuk-link-style-inverse;
  }

  &__close {
    float: right;
    margin-bottom: 0;
  }

  &--show {
    display: block;
  }
}
