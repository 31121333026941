// Extends the GDS task list pattern styles

// Custom styles

.hmrc-status-tag {
  color: $govuk-text-colour;
  font-family: "GDS Transport", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin-top: govuk-spacing(2);
  margin-bottom: govuk-spacing(1);

  @include govuk-media-query($from: 450px) {
    float: right;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.app-task-list__items {
  @include govuk-media-query($from: tablet) {
    padding-left: 0;
  }
}

.app-task-list__task-name {
  width: auto;

  > a {
    @extend .govuk-link !optional; // sass-lint:disable-line placeholder-in-extend
  }
}

// Taken from example in GDS prototype kit
// TODO: remove these and ensure we are extending official pattern if the task list is adopted permanantly by GDS

.app-task-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;

  @include govuk-media-query($from: tablet) {
    min-width: 550px;
  }
}

.app-task-list__section {
  display: table;

  @include govuk-font($size: 24, $weight: bold);
}

.app-task-list__section-number {
  display: table-cell;

  @include govuk-media-query($from: tablet) {
    min-width: govuk-spacing(6);
    padding-right: 0;
  }
}

/* stylelint-disable no-duplicate-selectors */
.app-task-list__items {
  @include govuk-font($size: 19);
  @include govuk-responsive-margin(9, "bottom");

  padding-left: 0;
  list-style: none;

  @include govuk-media-query($from: tablet) {
    padding-left: govuk-spacing(6);
  }
}

.app-task-list__item {
  margin-bottom: 0 !important;
  padding-top: govuk-spacing(2);
  padding-bottom: govuk-spacing(2);
  border-bottom: 1px solid $govuk-border-colour;

  @include govuk-clearfix;
}

.app-task-list__item:first-child {
  border-top: 1px solid $govuk-border-colour;
}

.app-task-list__task-name {
  display: block;

  @include govuk-media-query($from: 450px) {
    float: left;
  }
}
/* stylelint-enable no-duplicate-selectors */

.app-task-list__task-completed {
  margin-top: govuk-spacing(2);
  margin-bottom: govuk-spacing(1);

  @include govuk-media-query($from: 450px) {
    margin-top: 0;
    margin-bottom: 0;
    float: right;
  }
}
