@import "../../../../govuk-frontend/dist/govuk/settings/index";
@import "../../../../govuk-frontend/dist/govuk/tools/index";
@import "../../../../govuk-frontend/dist/govuk/helpers/index";
@import "../../../../govuk-frontend/dist/govuk/vendor/sass-mq";

.hmrc-currency-input__wrapper {
  position: relative;
}

.hmrc-currency-input__pence,
.hmrc-currency-input__unit--pence,
.hmrc-currency-input__unit {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  width: 1.5rem;
  border-right: 2px solid;
  background: govuk-colour("light-grey");
  text-align: center;

  @include govuk-font($size: 19, $weight: bold);
}

.hmrc-currency-input__unit {
  pointer-events: none;
}

.govuk-form-group--error .hmrc-currency-input__unit {
  top: 4px;
  bottom: 4px;
  left: 4px;
}

.hmrc-currency-input__unit--pence,
.hmrc-currency-input__pence {
  right: 2px;
  left: auto;
  width: 3rem;
  border-left: 2px solid;
  border-right-width: 0;
  line-height: 1.8;
}

.hmrc-currency-input__unit--pence {
  width: 1.5rem;
}

.govuk-form-group--error .hmrc-currency-input__pence,
.govuk-form-group--error .hmrc-currency-input__unit--pence {
  top: 4px;
  right: 4px;
  bottom: 4px;
}

.hmrc-currency-input__decimal {
  @include govuk-font($size: 36, $weight: bold);
}

.hmrc-currency-input__pence::after,
.hmrc-currency-input__unit::after,
.hmrc-currency-input__unit--pence::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.hmrc-currency-input__unit ~ .govuk-input {
  padding-left: 2rem;
}

.hmrc-currency-input__pence ~ .govuk-input {
  padding-right: 3.5rem;
}

.hmrc-currency-input__unit--pence ~ .govuk-input {
  padding-right: 2rem;
}

.hmrc-currency-input__group {
  display: inline-block;
  margin-bottom: 0;
}

// WCAG 2.1 focus update
// duplicating as non-supporting browsers choke on focus-within and throw the whole rule
.hmrc-currency-input__wrapper--has-focus .hmrc-currency-input__unit {
  top: 4px;
  bottom: 4px;
  left: 4px;
}

.hmrc-currency-input__wrapper:focus-within .hmrc-currency-input__unit {
  top: 4px;
  bottom: 4px;
  left: 4px;
}

.hmrc-currency-input__wrapper--has-focus .hmrc-currency-input__pence {
  top: 4px;
  right: 4px;
  bottom: 4px;
}

.hmrc-currency-input__wrapper:focus-within .hmrc-currency-input__pence {
  top: 4px;
  right: 4px;
  bottom: 4px;
}
